.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
h5, h6, p {
  font-size: 1.2rem; /* Adjust for larger screens */
}

@media (max-width: 768px) {
  h5, h6, p {
    font-size: 1rem; /* Adjust for smaller screens */
  }
} 


.soundcloud-container {
  position: relative;
  width: 100%;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.soundcloud-iframe {
  width: 100%;
  height: 500px; /* Default height for desktop */
  border-radius: 10px;
}

@media (max-width: 768px) {
  .soundcloud-iframe {
    height: 700px; /* Increase height for mobile view */
  }
}
