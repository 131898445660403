/*===================================================
#4.2 service-section
=====================================================*/

.service-single-item {
  position: relative;
  box-shadow: 0px 5px 9.8px 0.2px rgb(156, 156, 156);
  text-align: center;
  margin: 15px;
  padding: 20px;
  background: #b3dbfcb3;
  transition: all 0.3s ease-in-out;
}

.service-single-item:hover {
  box-shadow: 0px 5px 9.8px 0.2px rgb(93, 93, 93);
  background: #b0d9fa;
  transform: scale(1.05);
}

.service-text {
  padding: 20px;
}

.service-text h2 {
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.service-text h2 a {
  color: #242f6c;
}

.service-text h2 a:hover {
  color: #029ca5;
}

.service-single-img img {
  width: 100%;
  max-width: 150px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.service-area-2 {
  margin-bottom: 70px;
}

.wpo-event-area-2 {
  background: #fff;
  border-bottom: 1px solid #f9f9fc;
}

.urdu-font {
  font-family: 'Noto Nastaliq Urdu', sans-serif;
  line-height: normal;
}

.service-area-3 {
  padding-bottom: 70px;
}

.service-area-2.s2 {
  padding-top: 100px;
}


.ribbon-top-left {
  top: -7px;
  left: -7px;
}

.ribbon {
  width: 103px;
  height: 89px;
  overflow: hidden;
  position: absolute;
}

.ribbon-top-left span {
  right: -14px;
  top: 29px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon span {
  position: absolute;
  display: block;
  width: 180px;
  padding: 10px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
  color: #fff;
  font: 700 9px / 1 Lato, sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  text-transform: uppercase;
  text-align: center;
}

.box {
  position: relative;
}
@media(max-width: 767px) {
  .service-text h2 {
    font-size: 18px;
  }

  .service-single-item {
    margin: 5px;
    padding: 10px;
  }

  .service-area-2 {
    margin-bottom: 50px;
    padding: 35px;
  }
}

@media(max-width: 580px) {
  .service-text h2 {
    font-size: 15px;
  }

  .service-single-item {
    margin: 5px;
    padding: 8px;
  }

  .service-area-2 {
    margin-bottom: 50px;
    padding: 25px;
  }
}