/*---------------------------
  Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|Rubik:300,400,500,700&display=swap');

@font-face {
    font-family: 'Conv_Now-Bold';
    src: url('../../fonts/text-fonts/Now-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: url('../../fonts/text-fonts/now-regular.otf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: url('../../fonts/text-fonts/now-medium.otf') format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: url('../../fonts/text-fonts/now-light.otf') format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Now';
    src: url('../../fonts/text-fonts/now-thin.otf') format("truetype");
    font-weight: 200;
    font-style: normal;
}


/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
html {
    font-size: 16px;
}

body {
    font-family: 'Now'!important;
    background-color: #fff;
    font-size: 16px;
    font-size: 1rem;
    color: #525252;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

.App{
    overflow-x: hidden;
}

@media (max-width: 767px) {
    body {
        font-size: 14px;
    }
}

p {
    color: #777777;
    line-height: 1.8em;
    font-size: 16px;
    font-weight: 400;
}

h1,
h2 {
    font-family: 'Conv_Now-Bold';
    color: #242f6c;
}
h1,
h2, 
h3 {
   margin-top: 20px;
}

h3,
h4,
h5,
h6{
    font-family: 'Conv_Now-Bold';
}

ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none !important;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

a:hover {
    text-decoration: none!important;
}

img {
    max-width: 100%;
}

@media(min-width: 1200px){
  .container{
    width: 1200px;
    max-width: 100%;
 }
}

/*--------------------------------------------------------------
#0.2    Global Elements
--------------------------------------------------------------*/
.page-wrapper {
    position: relative;
    overflow: hidden;
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 120px 0;
}

@media (max-width: 991px) {
    .section-padding {
        padding: 90px 0;
    }
}

@media (max-width: 767px) {
    .section-padding {
        padding: 80px 0;
    }
}

/*** back to top **/
.back-to-top {
  background-color: rgba(0, 198, 209, 0.7);
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 2px solid #00c6d1;
  border-radius: 50%;
  text-align: center;
  display: none;
  position: fixed;
  z-index: 9999999;
  right: 15px;
  bottom: 15px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

@media (max-width: 991px) {
  .back-to-top {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.back-to-top:hover {
  background-color: #00a7d5;
}

.back-to-top i {
  font-size: 22px;
  font-size: 1.46667rem;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*** contact form error handling ***/
.contact-validation-active .error-handling-messages {
    width: 100% !important;
    margin-top: 15px !important;
}

.contact-validation-active label.error {
    color: red;
    font-size: 0.875rem;
    font-weight: normal;
    margin: 5px 0 0 0;
    text-align: left;
    display: block;
}

.contact-validation-active #loader {
    display: none;
    margin-top: 10px;
}

.contact-validation-active #loader i {
    font-size: 30px;
    font-size: 1.875rem;
    color: #029ca5;
    display: inline-block;
    -webkit-animation: rotating linear 2s infinite;
    animation: rotating linear 2s infinite;
}

.contact-validation-active #success,
.contact-validation-active #error {
    width: 100%;
    color: #fff;
    padding: 5px 10px;
    font-size: 16px;
    text-align: center;
    display: none;
}

@media (max-width: 767px) {

    .contact-validation-active #success,
    .contact-validation-active #error {
        font-size: 15px;
    }
}

.contact-validation-active #success {
    background-color: #009a00;
    border-left: 5px solid green;
    margin-bottom: 5px;
}

.contact-validation-active #error {
    background-color: #ff1a1a;
    border-left: 5px solid red;
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 5s alternate infinite;
    animation: rotating 5s alternate infinite;
}

/** for popup image ***/
.mfp-wrap {
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 99999;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999 !important;
}

.fancybox-wrap {
    z-index: 99999 !important;
}

.wpo-section-title {
    text-align: center;
    position: relative;
    max-width: 500px;
    margin: auto;
    margin-bottom: 70px;
}



.wpo-section-title span {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
    background: -webkit-linear-gradient(to right, #00b3d6, #00ded8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wpo-section-title h2 {
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    display: inline-block;
    position: relative;
    line-height: 60px;
}


@media (max-width: 767px) {
    .wpo-section-title h2 {
        font-size: 25px;
        line-height: 35px;
    }
}

.theme-btn,
.theme-btn-s2,
.theme-btn-s4,
.theme-btn-s3 {
    background: linear-gradient(to left, #00a7d5, #00ded8);
    background: -webkit-linear-gradient(to left, #00a7d5, #00ded8);
    background: -moz-linear-gradient(to left, #00a7d5, #00ded8);
    background: -o-linear-gradient(to left, #00a7d5, #00ded8);
    background: -ms-linear-gradient(to left, #00a7d5, #00ded8);
    background: -webkit-gradient(linear, left top, right top, from(#00a7d5), to(#00ded8));
    background: linear-gradient(to left, #00a7d5, #00ded8);
    color: #fff;
    display: inline-block;
    font-weight: 500;
    padding: 13px 30px;
    border: 0;
    border-radius: 55px;
    text-transform: capitalize;
    -webkit-box-shadow: 0 0 0 5px #c8eaf5;
    box-shadow: 0 0 0 5px #c8eaf5;
    -webkit-backface-visibility: hidden;
    z-index: 1;
    position: relative;
    line-height: 21px;
}

.theme-btn:after,
.theme-btn-s3:after,
.theme-btn-s4:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 55px;
    background: -webkit-linear-gradient(to left, #00ded8, #00a7d5);
    background: -moz-linear-gradient(to left, #00ded8, #00a7d5);
    background: -o-linear-gradient(to left, #00ded8, #00a7d5);
    background: -ms-linear-gradient(to left, #00ded8, #00a7d5);
    background: -webkit-gradient(linear, left top, right top, from(#00ded8), to(#00a7d5));
    background: linear-gradient(to left, #00ded8, #00a7d5);
    -webkit-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
    opacity: 0;
    z-index: -1;
}

.theme-btn:hover:after,
.theme-btn-s3:hover:after,
.theme-btn-s4:hover:after,
.view-cart-btn:hover:after {
    opacity: 1;
}

@media (max-width: 991px) {

    .theme-btn,
    .theme-btn-s2,
    .theme-btn-s4,
    .theme-btn-s3 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 25px;
        line-height: 26px;
    }
}

.theme-btn-s3 {
    border-radius: 5px;
    box-shadow: none;
}

.theme-btn-s3:after {
    border-radius: 5px;
}

.theme-btn:hover,
.theme-btn-s2:hover,
.theme-btn-s4:hover,
.theme-btn-s3:hover,
.theme-btn:focus,
.theme-btn-s2:focus,
.theme-btn-s4:focus,
.theme-btn-s3:focus,
.theme-btn:active,
.theme-btn-s2:active,
.theme-btn-s4:active,
.theme-btn-s3:active {
    background-color: #029ca5;
    color: #fff;
}

.theme-btn-s4 {
    font-weight: 400;
    color: #666666;
    transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
}

.theme-btn-s2 {
    border: none;
    background: none;
    border: 1px solid #fff;
    color: #fff;
}

.theme-btn-s2:hover,
.theme-btn-s4:hover,
.theme-btn-s2:focus,
.theme-btn-s4:focus,
.theme-btn-s2:active,
.theme-btn-s4:active {
    background-position: 100% !important;
    color: #fff;
}

.theme-btn-s3 {
    padding: 16px 30px 14px;
}

@media (max-width: 991px) {
    .theme-btn-s3 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 25px;
    }
}

.theme-btn-s4 {
    padding: 15px 48px;
    border-radius: 55px;
}

@media (max-width: 991px) {
    .theme-btn-s4 {
        font-size: 14px;
        font-size: 0.875rem;
        padding: 10px 25px;
    }
}

.view-cart-btn {
    display: block;
}

.social-links {
    overflow: hidden;
}

.social-links li {
    float: left;
    width: 35px;
    height: 35px;
    margin-right: 1px;
}

.social-links li a {
    background-color: #05f2ff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    display: block;
    color: #fff;
    text-align: center;
}

.social-links li a:hover {
    background-color: #029ca5;
}

/******************************
  #page title
******************************/
.page-title {
    background-color: #fcfaf8;
    width: 100%;
    height: 335px;
    text-align: center;
    position: relative;
}

@media (max-width: 991px) {
    .page-title {
        height: 300px;
    }
}

.page-title .container {
    height: 100%;
    display: table;
}

.page-title .container>.row {
    vertical-align: middle;
    display: table-cell;
}

.page-title h2 {
    font-size: 40px;
    font-size: 2.5rem;
    font-weight: 600;
    margin: 0 0 0.30em;
    text-transform: capitalize;
}

@media (max-width: 991px) {
    .page-title h2 {
        font-size: 35px;
        font-size: 2.1875rem;
    }
}

@media (max-width: 767px) {
    .page-title h2 {
        font-size: 30px;
        font-size: 1.875rem;
    }
}

.page-title .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .page-title .breadcrumb {
        text-align: center;
    }
}

.page-title .breadcrumb li {
    font-size: 16px;
    font-size: 1rem;
    margin-right: 5px;
    position: relative;
}

@media (max-width: 767px) {
    .page-title .breadcrumb li {
        font-size: 14px;
        font-size: 0.875rem;
    }
}

.page-title .breadcrumb li a {
    color: #029ca5;
}

.page-title .breadcrumb li a:hover {
    color: #00aeb8;
}

.page-title .breadcrumb>li+li {
    margin-left: 20px;
    padding-left: 15px;
}

.page-title .breadcrumb>li+li:before {
    content: "/";
    padding: 0;
    position: absolute;
    left: -14px;
    top: 1px;
    color: #029ca5;
}

.preloader {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
}

.preloader .sk-folding-cube {
    margin: 20px auto;
    width: 60px;
    height: 60px;
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 50px);
    -webkit-transform: rotateZ(45deg);
    -ms-transform: rotate(45deg);
    transform: rotateZ(45deg);
}

.preloader .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.preloader .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #029ca5;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
    animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
}

.preloader .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    -ms-transform: scale(1.1) rotate(90deg);
    transform: scale(1.1) rotateZ(90deg);
}

.preloader .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    -ms-transform: scale(1.1) rotate(180deg);
    transform: scale(1.1) rotateZ(180deg);
}

.preloader .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    -ms-transform: scale(1.1) rotate(270deg);
    transform: scale(1.1) rotateZ(270deg);
}

.preloader .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

.preloader .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

.preloader .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {

    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

@keyframes sk-foldCubeAngle {

    0%,
    10% {
        -webkit-transform: perspective(140px) rotateX(-180deg);
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }

    25%,
    75% {
        -webkit-transform: perspective(140px) rotateX(0deg);
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }

    90%,
    100% {
        -webkit-transform: perspective(140px) rotateY(180deg);
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}



.fixed-navbar {
    position: relative;
    z-index: 5555;
  }
  .fixed-navbar.active .header-style-1,
  .fixed-navbar.active .header-style-2,
  .fixed-navbar.active .header-style-3,
  .fixed-navbar.active .header-style-s3{
    position: fixed;
    z-index: 555;
    top: 0;
    left: 0;
    width: 100%;
    background: #090726;
    border: none;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
      -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
      -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
  }
  .fixed-navbar.active .header-style-2,
  .fixed-navbar.active .header-style-s3,
  .fixed-navbar.active .header-style-3{
    background: #fff;
  }

  .fixed-navbar.active .header-style-2{
    background: #033543;
  }