.mobileMenu {
    position: fixed;
    left: -270px;
    top: 0;
    z-index: 9999;
    height: 100vh;
    width: 270px;
    background: #fff;
    transition: all 0.3s ease 0s;
    box-shadow: 0px 10px 40px 0px rgba(50, 50, 50, 0.1);
    overflow-x: auto;
    overflow-y: scroll;
}
.responsivemenu .card-body{
    background: #fff;
}
.mobileMenu h2{
    padding-left: 20px;
}
.mobileMenu.show{
    left: 0;
}
.showmenu {
    position: absolute;
    right: 30px;
    top: 25px;
    z-index: 999;
    cursor: pointer;
}
.responsivemenu {
    list-style: none;
    padding-left: 0;
    /* padding-top: 30px; */
}
.clox {
  padding: 20px 20px 0px;
}
.responsivemenu li a,
.responsivemenu li p{
    display: block;
    padding: 13px 20px;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: capitalize;
    font-weight: 500;
    color: #666;
    cursor: pointer;
}
.responsivemenu li p{
    position: relative;
    margin-bottom: 0;
}

.responsivemenu .card{
  border:none
}
.responsivemenu .card-body{
  padding-top: 0;
}

.responsivemenu .card-body li {
    background: #fff;
}

.responsivemenu li a{
    position: relative;
}
.responsivemenu li i{
    position: absolute;
    right: 20px;
    top: 17px;
}
.showmenu i {
    font-size: 30px;
    color: #fff;
}

.mobileMenu{
    display: none;
}
.showmenu{
    display: none;
}
@media (max-width:992px){
    .showmenu {
        display: block;
        width: 40px;
        height: 40px;
        text-align: center;
        background: #029ca5;
        line-height: 52px;
        border-radius: 5px;
    }
    .mobileMenu{
        display: block;
    }
}


.middle-header-3 .showmenu {
    top: 36px;
}
.middle-header2 .showmenu {
    top: 26px;
}

.middle-header-3 .showmenu i{
    color: #fff;
}


@media(max-width:450px){
    .mobileMenu {
        left: -240px;
        width: 240px;
    }
    .responsivemenu li a, .responsivemenu li p {
        font-size: 14px;
    }
}

.mobileMenu::-webkit-scrollbar {
    width: 3px;
}


  .mobileMenu::-webkit-scrollbar-track {
    box-shadow: none;
  }
  .mobileMenu:hover::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .mobileMenu::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 1px solid transparent;
  }

  .mobileMenu:hover::-webkit-scrollbar-thumb  {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}