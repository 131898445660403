	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/12/2021 04:51
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff2") format("woff2"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  font-size: 50px;
  font-style: normal;
}

.flaticon-quran:before { content: "\f100"; }
.flaticon-allah-word:before { content: "\f101"; }
.flaticon-muhammad-word:before { content: "\f102"; }
.flaticon-prayer:before { content: "\f103"; }
.flaticon-business-and-finance:before { content: "\f104"; }
.flaticon-play:before { content: "\f105"; }
.flaticon-wall-clock:before { content: "\f106"; }
.flaticon-placeholder:before { content: "\f107"; }
.flaticon-call:before { content: "\f108"; }
.flaticon-shopping-cart:before { content: "\f109"; }
.flaticon-search:before { content: "\f10a"; }
.flaticon-envelope:before { content: "\f10b"; }
.flaticon-like:before { content: "\f10c"; }
.flaticon-share:before { content: "\f10d"; }
.flaticon-quote:before { content: "\f10e"; }
.flaticon-link:before { content: "\f10f"; }
.flaticon-facebook:before { content: "\f110"; }
.flaticon-twitter:before { content: "\f111"; }
.flaticon-google-plus:before { content: "\f112"; }
.flaticon-instagram:before { content: "\f113"; }
.flaticon-linkedin:before { content: "\f114"; }
.flaticon-taj-mahal:before { content: "\f115"; }
.flaticon-taj-mahal-1:before { content: "\f116"; }