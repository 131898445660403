
/*--------------------------------------------------------------
#0.5  wpo-footer
--------------------------------------------------------------*/
.wpo-site-footer,
.wpo-site-footer {
    background: #fff;
    position: relative;
    font-size: 15px;
    /*** about-widget ***/
    /*** link-widget ***/
    /*** resource-widget ***/
    /*** market-widget ***/
    /*** wpo-lower-footer ***/
    padding-top: 100px;
}

.wpo-news-letter-wrap {
    max-width: 970px;
    margin: auto;
    box-shadow: 0px 4px 9.5px 0.5px rgba(53, 53, 53, 0.1);
    padding: 60px;
    background: #f9fdfe;
}


.wpo-site-footer-2 {
    background: #fcfcfc;
}

.wpo-site-footer ul,
.wpo-site-footer-2 ul {
    list-style: none;
}

.wpo-site-footer p,
.wpo-site-footer li {
    color: #444444;
}

.wpo-site-footer-2 p,
.wpo-site-footer-2 li {
    color: #444444;
}

.wpo-site-footer .container,
.wpo-site-footer-2 .container {
    position: relative;
}

.wpo-site-footer .wpo-upper-footer,
.wpo-site-footer-2 .wpo-upper-footer {
    padding-bottom: 100px;
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-upper-footer,
    .wpo-site-footer-2 .wpo-upper-footer {
        padding: 0 0 20px;
    }

    .wpo-news-letter-wrap {
        padding: 60px 10px;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .wpo-upper-footer,
    .wpo-site-footer-2 .wpo-upper-footer {
        padding: 0 0 10px;
    }

    .wpo-news-letter-wrap {
        padding: 30px 10px;
    }

    .wpo-news-letter-section h3,
    .wpo-news-letter-section-2 h3 {
        margin: 0px 0 0.2em;
    }
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-upper-footer .col,
    .wpo-site-footer-2 .wpo-upper-footer .col {
        min-height: 235px;
        margin-bottom: 70px;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .wpo-upper-footer .col,
    .wpo-site-footer-2 .wpo-upper-footer .col {
        min-height: auto;
        margin-bottom: 60px;
    }
}

.wpo-site-footer .widget-title,
.wpo-site-footer-2 .widget-title {
    margin-bottom: 30px;
}

@media (max-width: 767px) {

    .wpo-site-footer .widget-title,
    .wpo-site-footer-2 .widget-title {
        margin-bottom: 20px;
    }
}

.wpo-site-footer .widget-title h3,
.wpo-site-footer-2 .widget-title h3 {
    font-size: 23px;
    font-weight: bold;
    margin: 0;
    text-transform: capitalize;
    position: relative;
}

.wpo-site-footer-2 .widget-title h3 {
    color: #062265;
}

@media (max-width: 1200px) {
    .wpo-site-footer .widget-title h3,
    .wpo-site-footer-2 .widget-title h3 {
        font-size: 18px;
    }
}
@media (max-width: 991px) {

    .wpo-site-footer .widget-title h3,
    .wpo-site-footer-2 .widget-title h3 {
        font-size: 20px;
        font-size: 1.25rem;
    }
}

.wpo-site-footer .about-widget .logo {
    max-width: 180px;
    margin-top: 0;
}

.wpo-site-footer .about-widget p,
.wpo-site-footer-2 .about-widget p {
    margin-bottom: 0.8em;
    line-height: 1.9em;
}

.wpo-site-footer .about-widget p:last-child,
.wpo-site-footer-2 .about-widget p:last-child {
    margin-bottom: 0;
}

.wpo-site-footer .about-widget ul,
.wpo-site-footer-2 .about-widget ul {
    overflow: hidden;
    padding-top: 10px;
}

.wpo-site-footer .about-widget ul li,
.wpo-site-footer-2 .about-widget ul li {
    font-size: 22px;
    float: left;
}

.wpo-site-footer .about-widget ul>li+li,
.wpo-site-footer-2 .about-widget ul>li+li {
    margin-left: 25px;
}

.wpo-site-footer .about-widget ul a {
    background: -webkit-linear-gradient(#00b3d6, #00ded8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wpo-site-footer-2 .about-widget ul a {
    color: #404040;
}

.wpo-site-footer .about-widget ul a:hover,
.wpo-site-footer-2 .about-widget ul a:hover {
    background: -webkit-linear-gradient(#00b3d6, #00ded8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wpo-site-footer-2 .about-widget ul a {
    color: #029ca5;
}

.wpo-site-footer .link-widget,
.wpo-site-footer-2 .link-widget {
    overflow: hidden;
}

@media screen and (min-width: 1200px) {
    .wpo-site-footer .link-widget {
        padding-left: 20px;
    }

    .wpo-site-footer-2 .link-widget {
        padding-left: 10px;
    }
}

@media (max-width: 1199px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        padding-left: 20px;
    }
}

@media (max-width: 991px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        padding-left: 0;
    }
}

@media (max-width: 767px) {

    .wpo-site-footer .link-widget,
    .wpo-site-footer-2 .link-widget {
        max-width: 350px;
    }
}

.wpo-site-footer .link-widget ul li,
.wpo-site-footer-2 .link-widget ul li {
    position: relative;
}

.wpo-site-footer .link-widget ul a,
.wpo-site-footer-2 .link-widget ul a {
    color: #444444;
}

.wpo-site-footer-2 .link-widget ul a {
    color: #404040;
}

.wpo-site-footer .link-widget ul a:hover,
.wpo-site-footer-2 .link-widget ul a:hover,
.wpo-site-footer .link-widget ul li:hover:before,
.wpo-site-footer-2 .link-widget ul li:hover:before {
    text-decoration: underline;
}

.wpo-site-footer .link-widget ul>li+li,
.wpo-site-footer-2 .link-widget ul>li+li {
    margin-top: 15px;
}

@media screen and (min-width: 1200px) {

    .wpo-site-footer .resource-widget,
    .wpo-site-footer-2 .resource-widget {
        padding-left: 0px;
    }
}

.wpo-site-footer .contact-ft ul li .fi:before,
.wpo-site-footer-2 .contact-ft ul li .fi:before {
    font-size: 20px;
    margin-right: 15px;
}

.wpo-site-footer .contact-ft ul li,
.wpo-site-footer-2 .contact-ft ul li {
    padding-bottom: 10px;
}

.wpo-site-footer .contact-ft,
.wpo-site-footer-2 .contact-ft {
    margin-top: 20px;
}

.wpo-site-footer .wpo-lower-footer,
.wpo-site-footer-2 .wpo-lower-footer {
    text-align: center;
    position: relative;
}

.wpo-site-footer .wpo-lower-footer .row,
.wpo-site-footer-2 .wpo-lower-footer .row {
    border-top: 1px solid #e3eff0;
}

.wpo-site-footer .wpo-lower-footer .row,
.wpo-site-footer-2 .wpo-lower-footer .row {
    padding: 20px 0;
    position: relative;
}


.wpo-site-footer .wpo-lower-footer .copyright,
.wpo-site-footer-2 .wpo-lower-footer .copyright {
    display: inline-block;
    font-size: 15px;
    font-size: 0.9375rem;
    margin: 0;
}

@media (max-width: 991px) {

    .wpo-site-footer .wpo-lower-footer .copyright,
    .wpo-site-footer-2 .wpo-lower-footer .copyright {
        float: none;
        display: block;
    }
}

.wpo-site-footer .wpo-lower-footer .copyright a,
.wpo-site-footer-2 .wpo-lower-footer .copyright a {
    color: #fff;
    text-decoration: underline;
}

.wpo-site-footer .news-text h3 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-top: -5px;
    line-height: 25px;
    margin-bottom: 0px;
    font-family: "Open Sans", sans-serif;
}

.wpo-site-footer .news-text span {
    color: #ccc;
    font-size: 12px;
}

.wpo-site-footer .news-text {
    overflow: hidden;
    padding-left: 10px;
}

.wpo-site-footer .news-wrap {
    overflow: hidden;
    margin-bottom: 20px;
}

.wpo-site-footer .news-img {
    float: left;
}

.sticky-header {
    width: 100%;
    position: fixed;
    left: 0;
    top: -200px;
    z-index: 9999;
    opacity: 0;
    -webkit-transition: all 0.7s;
    -moz-transition: all 0.7s;
    -o-transition: all 0.7s;
    -ms-transition: all 0.7s;
    transition: all 0.7s;
    background: white;
    -webkit-box-shadow: 0 0 15px rgba(218, 218, 218, 0.5);
    box-shadow: 0 0 15px rgba(218, 218, 218, 0.5);
}

.sticky-on {
    opacity: 1;
    top: 0;
    background: #033543 !important;
}

.wpo-header-style-3 .sticky-on {
    opacity: 1;
    top: 0;
    background: #fff !important;
}

.wpo-header-style-1 .sticky-header,
.wpo-header-style-2 .sticky-header {
    background-color: #fff;
}

.wpo-header-style-2 .navigation {
    background: transparent;
}

.youtube-links li a:hover,
.youtube-links li a.active {
	color: #9d29bd;
	text-decoration: none;
  font-weight: bold;
}

@media(max-width: 1200px) {
    .wpo-site-footer .news-text h3 {
        font-size: 13px;
    }

    .wpo-site-footer .contact-ft ul li,
    .wpo-site-footer-2 .contact-ft ul li {
        padding-bottom: 10px;
        font-size: 13px;
    }
}

@media(max-width: 575px) {

    .wpo-site-footer,
    .wpo-site-footer {
        padding-top: 60px;
    }

    .wpo-site-footer .wpo-upper-footer .col,
    .wpo-site-footer-2 .wpo-upper-footer .col {
        min-height: auto;
        margin-bottom: 30px;
    }
}